import React, { useEffect, useRef, useState } from 'react';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import 'chartjs-adapter-moment';

import { DatePicker, Layout, Select } from '@components/common';
import CalendarSelect from '../components/common/Select/CalenderSelect';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import moment from 'moment';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { IServiceProvider } from 'interfaces/team/serviceProvider.interface';
import { RoleEnum } from 'constants/enums';
import { useMediaQuery } from 'react-responsive';

const Dashboard = () => {
    const [revenueByServiceData, setRevenueByServiceData] = useState<any>();
    const [revenueByServiceProviderData, setRevenueByServiceProviderData] = useState<any>();
    const [appointmentsByServiceProviderData, setAppointmentsByServiceProviderData] =
        useState<any>();
    const [appointmentsByPlatformData, setAppointmentsByPlatformData] = useState<any>();

    // Ads statistics data
    const [newCustomersFromAds, setNewCustomersFromAds] = useState<any>();
    const [revenueFromConversions, setRevenueFromConversions] = useState<any>();
    const [totalAppointmentConversions, setTotalAppointmentConversions] = useState<any>();
    // Ads charts step size
    const [newCustomersFromAdsStepSize, setNewCustomersFromAdsStepSize] = useState<number>(1);
    const [revenueFromConversionsStepSize, setRevenueFromConversionsStepSize] = useState<number>(1);
    const [totalAppointmentConversionsStepSize, setTotalAppointmentConversionsStepSize] =
        useState<number>(1);
    // Ads charts time formats
    const [newCustomersFromAdsDateFormat, setNewCustomersFromAdsDateFormat] =
        useState<string>('YYYY-MM-DD');
    const [revenueFromConversionsDateFormat, setRevenueFromConversionsDateFormat] =
        useState<string>('YYYY-MM-DD');
    const [totalAppointmentConversionsDateFormat, setTotalAppointmentConversionsDateFormat] =
        useState<string>('YYYY-MM-DD');

    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [totalAppointments, setTotalAppointments] = useState<number>(0);
    const [totalCancelledAppointments, setTotalCancelledAppointments] = useState<number>(0);

    const [dashboardLeaderboardData, setDashboardLeaderboardData] = useState<any>();

    const [serviceProviderOptions, setServiceProviderOptions] = useState<any>([
        { id: 'All', label: 'All' },
    ]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>({
        id: 'All',
        label: 'All',
    });

    const serviceColorMap = [
        '#00beb780',
        '#ff5e0080',
        '#ff000080',
        '#c400ff80',
        '#3df20080',
        '#0055ff80',
        '#00fffb80',
        '#0026ff80',
        '#00b2ff80',
        '#6600ff80',
        '#ff007f80',
        '#00e68e80',
        '#c8ff0080',
        '#ff2ee380',
        '#f200ff80',
        '#0084ff80',
        '#ff2f0080',
        '#bd005e80',
        '#bd000080',
        '#00d9ff80',
        '#9500ff80',
        '#e0000080',
        '#00d11880',
        '#6fff0080',
        '#ff00ae80',
        '#e6007380',
        '#ffea0080',
        '#ffbb0080',
        '#00f2c280',
        '#9dff0080',
        '#0fe00080',
        '#ff8c0080',
    ];

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('This Week');
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
    const [timeframe, setTimeframe] = useState<string>('week');

    const timeRangeStart = selectedDate.clone().startOf(timeframe as moment.unitOfTime.StartOf);
    const timeRangeEnd = selectedDate.clone().endOf(timeframe as moment.unitOfTime.StartOf);

    const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | undefined>(
        moment().add(1, 'week')
    );

    const disclaimerRef = useRef<HTMLDivElement>(null);

    const [openSnackbar] = useSnackbar();
    const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const showAdsDashboard = _userData?.user?.business_id?.show_ads_dashboard;

    /*
     * Setup Chart.js
     */
    ChartJS.register(
        ArcElement,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        TimeScale
    );

    const getStatisticRevenueByService = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/revenue-by-service?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setRevenueByServiceData(undefined);
                    setLoading(false);
                    return;
                }

                const serviceNames = res.data.map((datapoint: any) => datapoint.name);
                const serviceRevenues = res.data.map((datapoint: any) => datapoint.revenue);
                const serviceColors = res.data.map(
                    (datapoint: any) => serviceColorMap[datapoint.color]
                );
                setRevenueByServiceData({
                    labels: serviceNames,
                    datasets: [
                        {
                            label: 'Revenue per Service',
                            data: serviceRevenues,
                            backgroundColor: serviceColors,
                        },
                    ],
                });
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const getStatisticRevenueByServiceProvider = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/revenue-by-service-provider?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setRevenueByServiceProviderData(undefined);
                    setTotalRevenue(0);
                    setLoading(false);
                    return;
                }

                const serviceProviderNames = res.data.map((datapoint: any) => datapoint.name);
                const serviceProviderRevenues = res.data.map((datapoint: any) => datapoint.revenue);
                setRevenueByServiceProviderData({
                    labels: serviceProviderNames,
                    datasets: [
                        {
                            label: 'Revenue',
                            data: serviceProviderRevenues,
                        },
                    ],
                });

                /*
                 * Add up revenue to get total revenue
                 */
                let revenueSum = 0;
                res.data.forEach((datapoint: any) => (revenueSum += datapoint.revenue));
                setTotalRevenue(revenueSum);

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const getStatisticAppointmentsByServiceProvider = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/appointments-by-service-provider?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setAppointmentsByServiceProviderData(undefined);
                    setTotalAppointments(0);
                    setLoading(false);
                    return;
                }

                const serviceProviderNames = res.data.map((datapoint: any) => datapoint.name);
                const serviceProviderAppointmentCounts = res.data.map(
                    (datapoint: any) => datapoint.count
                );
                setAppointmentsByServiceProviderData({
                    labels: serviceProviderNames,
                    datasets: [
                        {
                            label: '# of Appointments',
                            data: serviceProviderAppointmentCounts,
                        },
                    ],
                });

                /*
                 * Add up appointment counts to get total appointment count
                 */
                let appointmentSum = 0;
                res.data.forEach((datapoint: any) => (appointmentSum += datapoint.count));
                setTotalAppointments(appointmentSum);

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };
    const getStatisticAppointmentsByPlatform = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/appointments-by-platform?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setAppointmentsByPlatformData(undefined);
                    setLoading(false);
                    return;
                }

                const platformLabels = res.data.map((datapoint: any) => toTitleCase(datapoint._id));
                const platformAppointmentCounts = res.data.map((datapoint: any) => datapoint.count);
                const platformColors = res.data.map((datapoint: any) => {
                    switch (datapoint._id) {
                        case 'web':
                            return '#6600ff80';
                        case 'widget':
                            return '#0055ff80';
                        case 'mobile':
                            return '#e0000080';
                        default:
                            return '#ffbb0080';
                    }
                });
                setAppointmentsByPlatformData({
                    labels: platformLabels,
                    datasets: [
                        {
                            label: 'Appointment Count Per Platform',
                            data: platformAppointmentCounts,
                            backgroundColor: platformColors,
                        },
                    ],
                });
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getNewCustomerConversionsOverTime = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/new-customers-from-ads?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setNewCustomersFromAds(undefined);
                    setLoading(false);
                    return;
                }

                /*
                 * Generate X-axis labels
                 */
                let xAxisLabels = [];
                for (const dStr of res.data.map((datap: any) => datap.date)) {
                    if (dStr.length > 10) {
                        setNewCustomersFromAdsDateFormat('MMM D hh:mm a');
                        xAxisLabels.push(moment(dStr).format('MMM D hh:mm a'));
                    } else {
                        setNewCustomersFromAdsDateFormat('YYYY-MM-DD');
                        xAxisLabels.push(dStr);
                    }
                }

                /*
                 * Setup Y-axis Step Size
                 */
                let maxVal: number = 0,
                    minVal = 99999999999;
                for (const item of res.data) {
                    if (item.count > maxVal) {
                        maxVal = item.count;
                    }
                    if (item.count < minVal) {
                        minVal = item.count;
                    }
                }
                const valDiff = maxVal - minVal;
                const numSteps = 5;
                const stepSize = parseFloat(Math.ceil(valDiff / numSteps).toPrecision(1));
                setNewCustomersFromAdsStepSize(stepSize);

                let pointRadius = 5;
                let pointHoverRadius = 10;
                if (res.data.length > 10) {
                    pointRadius = 0;
                    pointHoverRadius = 5;
                }

                setNewCustomersFromAds({
                    labels: xAxisLabels,
                    datasets: [
                        {
                            label: 'New Customers from Ads',
                            data: res.data.map((datap: any) => datap.count),
                            borderColor: 'rgb(29, 162, 255)',
                            backgroundColor: 'rgb(29, 162, 255)',
                            borderWidth: 4,
                            pointRadius,
                            pointHoverRadius,
                            tension: 0.3,
                        },
                    ],
                });

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getRevenueFromConversions = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/revenue-from-conversions?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setRevenueFromConversions(undefined);
                    setLoading(false);
                    return;
                }

                /*
                 * Generate X-axis labels
                 */
                let xAxisLabels = [];
                for (const dStr of res.data.map((datap: any) => datap.date)) {
                    if (dStr.length > 10) {
                        setRevenueFromConversionsDateFormat('MMM D hh:mm a');
                        xAxisLabels.push(moment(dStr).format('MMM D hh:mm a'));
                    } else {
                        setRevenueFromConversionsDateFormat('YYYY-MM-DD');
                        xAxisLabels.push(dStr);
                    }
                }

                /*
                 * Setup Y-axis Step Size
                 */
                let maxVal: number = 0,
                    minVal = 99999999999;
                for (const item of res.data) {
                    if (item.revenue > maxVal) {
                        maxVal = item.revenue;
                    }
                    if (item.revenue < minVal) {
                        minVal = item.revenue;
                    }
                }
                const valDiff = maxVal - minVal;
                const numSteps = 5;
                const stepSize = parseFloat(Math.ceil(valDiff / numSteps).toPrecision(1));
                setRevenueFromConversionsStepSize(stepSize);

                let pointRadius = 5;
                let pointHoverRadius = 10;
                if (res.data.length > 10) {
                    pointRadius = 0;
                    pointHoverRadius = 5;
                }

                setRevenueFromConversions({
                    labels: xAxisLabels,
                    datasets: [
                        {
                            label: 'Revenue from Conversions',
                            data: res.data.map((datap: any) => datap.revenue),
                            borderColor: 'rgb(17, 140, 79)',
                            backgroundColor: 'rgb(17, 140, 79)',
                            borderWidth: 4,
                            pointRadius,
                            pointHoverRadius,
                            tension: 0.3,
                        },
                    ],
                });

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getTotalAppointmentConversions = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/total-appointment-conversions?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setTotalAppointmentConversions(undefined);
                    setLoading(false);
                    return;
                }

                /*
                 * Generate X-axis labels
                 */
                let xAxisLabels = [];
                for (const dStr of res.data.map((datap: any) => datap.date)) {
                    if (dStr.length > 10) {
                        setTotalAppointmentConversionsDateFormat('MMM D hh:mm a');
                        xAxisLabels.push(moment(dStr).format('MMM D hh:mm a'));
                    } else {
                        setTotalAppointmentConversionsDateFormat('YYYY-MM-DD');
                        xAxisLabels.push(dStr);
                    }
                }

                /*
                 * Setup Y-axis Step Size
                 */
                let maxVal: number = 0,
                    minVal = 99999999999;
                for (const item of res.data) {
                    if (item.count > maxVal) {
                        maxVal = item.count;
                    }
                    if (item.count < minVal) {
                        minVal = item.count;
                    }
                }
                const valDiff = maxVal - minVal;
                const numSteps = 5;
                const stepSize = parseFloat(Math.ceil(valDiff / numSteps).toPrecision(1));
                setTotalAppointmentConversionsStepSize(stepSize);

                let pointRadius = 5;
                let pointHoverRadius = 10;
                if (res.data.length > 10) {
                    pointRadius = 0;
                    pointHoverRadius = 5;
                }

                setTotalAppointmentConversions({
                    labels: xAxisLabels,
                    datasets: [
                        {
                            label: '# Ad Conversions',
                            data: res.data.map((datap: any) => datap.count),
                            borderColor: 'rgb(180, 130, 255)',
                            backgroundColor: 'rgb(180, 130, 255)',
                            borderWidth: 4,
                            pointRadius,
                            pointHoverRadius,
                            tension: 0.3,
                        },
                    ],
                });

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getStatisticTotalCancelledAppointments = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        if (selectedServiceProvider.id !== 'All') {
            uri = `${uri}&serviceProviderId=${selectedServiceProvider.id}`;
        }

        api.get(`/statistics/total-cancelled-appointments?${uri}`)
            .then((res: any) => {
                if (!res.data.total) {
                    setTotalCancelledAppointments(0);
                    setLoading(false);
                    return;
                }

                setTotalCancelledAppointments(res.data.total);

                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getDashboardLeaderboardData = () => {
        setLoading(true);

        let uri;
        if (timeframe === 'custom') {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&endDate=${selectedEndDate?.format(
                'YYYY-MM-DD'
            )}`;
        } else {
            uri = `date=${selectedDate.format('YYYY-MM-DD')}&timeframe=${timeframe}`;
        }

        api.get(`/statistics/dashboard/leaderboard?${uri}`)
            .then((res: any) => {
                if (!res.data.length) {
                    setDashboardLeaderboardData(undefined);
                    setLoading(false);
                    return;
                }

                setDashboardLeaderboardData(res.data);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getAllServiceProvidersAsOptions = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=1&pageSize=50`)
            .then((res: any) => {
                const providers: IServiceProvider[] = res.data.serviceProvider;
                // build options list
                let options = [];
                providers.forEach((provider: IServiceProvider) => {
                    options.push({
                        id: provider._id,
                        label: provider.name,
                        icon: provider.photo,
                    });
                });

                // add the default "All" option
                options.unshift({ id: 'All', label: 'All' });

                setServiceProviderOptions(options);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        // if user has team_dashboard permission, fetch service provider options
        if (_userData.user.permissions.team_dashboard) {
            getAllServiceProvidersAsOptions();
        } else if (_userData.user_login === RoleEnum.SERVICEPROVIDER) {
            // else for service provider just set to the current user
            setSelectedServiceProvider({
                id: _userData.user._id,
                label: _userData.user.name,
                icon: _userData.user.photo,
            });
        } else {
            // if we make it here, user shouldn't be viewing this page
            openSnackbar('You dont have permission to view any dashboard statistics.');
        }
    }, []);

    useEffect(() => {
        // don't try to fetch data if custom date range is invalid
        if (timeframe === 'custom' && selectedDate.isAfter(selectedEndDate, 'day')) {
            return;
        }

        if (selectedServiceProvider.id === 'All' && _userData.user.permissions.team_dashboard) {
            getDashboardLeaderboardData();
        }
        getStatisticRevenueByService();
        getStatisticRevenueByServiceProvider();
        getStatisticAppointmentsByServiceProvider();
        getStatisticAppointmentsByPlatform();
        getStatisticTotalCancelledAppointments();

        if (showAdsDashboard) {
            getNewCustomerConversionsOverTime();
            getRevenueFromConversions();
            getTotalAppointmentConversions();
        }
    }, [selectedDate, selectedEndDate, timeframe, selectedServiceProvider]);

    const changeTimeRange = (val: string) => {
        setSelectedTimeRange(val);
        switch (val) {
            case 'Today':
                setSelectedDate(moment());
                setTimeframe('day');
                break;
            case 'Yesterday':
                setSelectedDate(moment().subtract(1, 'day'));
                setTimeframe('day');
                break;
            case 'Tomorrow':
                setSelectedDate(moment().add(1, 'day'));
                setTimeframe('day');
                break;
            case 'This Week':
                setSelectedDate(moment());
                setTimeframe('week');
                break;
            case 'Last Week':
                setSelectedDate(moment().subtract(1, 'week'));
                setTimeframe('week');
                break;
            case 'Next Week':
                setSelectedDate(moment().add(1, 'week'));
                setTimeframe('week');
                break;
            case 'This Month':
                setSelectedDate(moment());
                setTimeframe('month');
                break;
            case 'Last Month':
                setSelectedDate(moment().subtract(1, 'month'));
                setTimeframe('month');
                break;
            case 'Next Month':
                setSelectedDate(moment().add(1, 'month'));
                setTimeframe('month');
                break;
            case 'Custom':
                setTimeframe('custom');
                break;
            default:
                console.error('Invalid time range selection.');
        }
    };

    const chartPlaceholder = (title: string, description: string) => {
        return (
            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                <ChartContainer>
                    <div>
                        <ChartTitle>{title}</ChartTitle>
                        <CustomSpan>{description}</CustomSpan>
                    </div>
                    <NoDataText>No data to display.</NoDataText>
                </ChartContainer>
            </ChartWrapper>
        );
    };

    const wideChartPlaceholder = (title: string, description: string) => {
        return (
            <ChartWrapper xxl={6} xl={12} lg={12} md={12} sm={12}>
                <ChartContainer>
                    <div>
                        <ChartTitle>{title}</ChartTitle>
                        <CustomSpan>{description}</CustomSpan>
                    </div>
                    <NoDataText>No data to display.</NoDataText>
                </ChartContainer>
            </ChartWrapper>
        );
    };

    const scrollToDisclaimer = () => {
        if (!disclaimerRef.current) {
            return;
        }

        disclaimerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    /*
     * Utility Functions
     */

    const toTitleCase = (str: string) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const specificProviderSelected = selectedServiceProvider.id !== 'All';

    return (
        <Wrapper id="container">
            {loading && <LoaderOverlay />}
            <Content>
                <TimeSelectionCard>
                    <WeekHeading>
                        <TimeSelectionSection>
                            {timeframe === 'custom' ? (
                                <CalendarDate2>
                                    <DatePicker
                                        date={selectedDate}
                                        dateFormat={'MMM D -'}
                                        onChange={setSelectedDate}
                                    />
                                    <span>&nbsp;</span>
                                    <DatePicker
                                        date={selectedEndDate ?? moment().add(1, 'week')}
                                        dateFormat={'MMM D'}
                                        onChange={setSelectedEndDate}
                                    />
                                </CalendarDate2>
                            ) : (
                                <CalendarDate>
                                    {timeframe === 'day' && moment(timeRangeStart).format('MMMM D')}
                                    {timeframe === 'week' &&
                                        `${moment(timeRangeStart).format('MMM D')} - ${moment(
                                            timeRangeEnd
                                        ).format('MMM  D')}`}
                                    {timeframe === 'month' && moment(timeRangeStart).format('MMMM')}
                                </CalendarDate>
                            )}
                            <Select
                                options={[
                                    { label: 'Today' },
                                    { label: 'Yesterday' },
                                    { label: 'Tomorrow' },
                                    { label: 'This Week' },
                                    { label: 'Last Week' },
                                    { label: 'Next Week' },
                                    { label: 'This Month' },
                                    { label: 'Last Month' },
                                    { label: 'Next Month' },
                                    { label: 'Custom' },
                                ]}
                                value={selectedTimeRange}
                                onChange={(val: string) => changeTimeRange(val)}
                                noKeyBoard
                            />
                            {!isMobile && _userData.user.permissions.team_dashboard && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <CalendarSelect
                                        id={selectedServiceProvider.id}
                                        value={selectedServiceProvider.label}
                                        options={serviceProviderOptions}
                                        onChange={(val: any) =>
                                            setSelectedServiceProvider(
                                                serviceProviderOptions.find((providerOpt: any) => {
                                                    return providerOpt.id === val.id;
                                                })
                                            )
                                        }
                                        readOnly
                                    />
                                </div>
                            )}
                        </TimeSelectionSection>
                        {isMobile && _userData.user.permissions.team_dashboard && (
                            <TimeSelectionSection>
                                <CalendarSelect
                                    id={selectedServiceProvider.id}
                                    value={selectedServiceProvider.label}
                                    options={serviceProviderOptions}
                                    onChange={(val: any) =>
                                        setSelectedServiceProvider(
                                            serviceProviderOptions.find((providerOpt: any) => {
                                                return providerOpt.id === val.id;
                                            })
                                        )
                                    }
                                    fullWidth
                                    readOnly
                                />
                            </TimeSelectionSection>
                        )}
                    </WeekHeading>
                </TimeSelectionCard>
                <AppointmentsDiv>
                    <Row>
                        {revenueByServiceData ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>Revenue Per Service</ChartTitle>
                                        <ChartDescription>
                                            Top Performing Services: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Doughnut
                                        data={revenueByServiceData}
                                        options={{
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${tooltipItem.label}: ${(
                                                                tooltipItem.raw as number
                                                            ).toLocaleString(undefined, {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                            })}`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                'Revenue Per Service',
                                `Top Performing Services: ${selectedTimeRange}`
                            )
                        )}
                        {revenueByServiceProviderData ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>Revenue Per Service Provider</ChartTitle>
                                        <ChartDescription>
                                            Top Earners: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Bar
                                        data={revenueByServiceProviderData}
                                        options={{
                                            backgroundColor: 'rgb(17, 140, 79)',
                                            aspectRatio: 1,
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    ticks: {
                                                        callback: function (value, index, ticks) {
                                                            return `$${value.toLocaleString()}`; // include dollar sign on ticks
                                                        },
                                                    },
                                                },
                                            },
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${(
                                                                tooltipItem.raw as number
                                                            ).toLocaleString(undefined, {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                            })}`;
                                                        },
                                                    },
                                                },
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                'Revenue Per Service Provider',
                                `Top Earners: ${selectedTimeRange}`
                            )
                        )}
                        {appointmentsByServiceProviderData ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>Appointments Per Service Provider</ChartTitle>
                                        <ChartDescription>
                                            Top Booked Providers: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Bar
                                        data={appointmentsByServiceProviderData}
                                        options={{
                                            backgroundColor: 'rgb(0, 120, 204)',
                                            aspectRatio: 1,
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${
                                                                tooltipItem.raw as number
                                                            } Appointments`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                'Appointments Per Service Provider',
                                `Top Booked Providers: ${selectedTimeRange}`
                            )
                        )}
                        {appointmentsByPlatformData ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>Appointments by Source</ChartTitle>
                                        <ChartDescription>
                                            How You Get Booked: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Doughnut
                                        data={appointmentsByPlatformData}
                                        options={{
                                            backgroundColor: 'rgb(0, 120, 204)',
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${tooltipItem.label}: ${tooltipItem.raw} Appointments`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                'Appointments by Source',
                                `How You Get Booked: ${selectedTimeRange}`
                            )
                        )}
                    </Row>
                </AppointmentsDiv>

                <OverlayContainer fluid>
                    <Row>
                        {newCustomersFromAds ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>New Customers from Ads</ChartTitle>
                                        <ChartDescription>
                                            Customers Acquired by Ads: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Line
                                        data={newCustomersFromAds}
                                        options={{
                                            aspectRatio: 1,
                                            scales: {
                                                x: {
                                                    type: 'time',
                                                    time: {
                                                        tooltipFormat:
                                                            newCustomersFromAdsDateFormat,
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                        maxRotation: 0,
                                                        major: {
                                                            enabled: true,
                                                        },
                                                    },
                                                },
                                                y: {
                                                    suggestedMin: 0,
                                                    suggestedMax: 5,
                                                    ticks: {
                                                        stepSize: newCustomersFromAdsStepSize,
                                                    },
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                'New Customers from Ads',
                                `Customers Acquired by Ads: ${selectedTimeRange}`
                            )
                        )}
                        {totalAppointmentConversions ? (
                            <ChartWrapper xxl={3} xl={6} lg={6} md={6} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle># Ad Conversions</ChartTitle>
                                        <ChartDescription>
                                            Total Appointments from Ads: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Line
                                        data={totalAppointmentConversions}
                                        options={{
                                            backgroundColor: 'rgba(102, 0, 255, 0.5)',
                                            aspectRatio: 1,
                                            scales: {
                                                x: {
                                                    type: 'time',
                                                    time: {
                                                        tooltipFormat:
                                                            totalAppointmentConversionsDateFormat,
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                        maxRotation: 0,
                                                        major: {
                                                            enabled: true,
                                                        },
                                                    },
                                                },
                                                y: {
                                                    suggestedMin: 0,
                                                    suggestedMax: 5,
                                                    ticks: {
                                                        stepSize:
                                                            totalAppointmentConversionsStepSize,
                                                    },
                                                },
                                            },
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${
                                                                tooltipItem.raw as number
                                                            } Appointments`;
                                                        },
                                                    },
                                                },
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            chartPlaceholder(
                                '# Ad Conversions',
                                `Total Appointments from Ads: ${selectedTimeRange}`
                            )
                        )}
                        {revenueFromConversions ? (
                            <ChartWrapper xxl={6} xl={12} lg={12} md={12} sm={12}>
                                <ChartContainer>
                                    <div>
                                        <ChartTitle>Revenue from Conversions</ChartTitle>
                                        <ChartDescription>
                                            Revenue from Ads: {selectedTimeRange}
                                        </ChartDescription>
                                        <DisclaimerLink onClick={scrollToDisclaimer}>
                                            View Disclaimer
                                        </DisclaimerLink>
                                    </div>
                                    <Line
                                        data={revenueFromConversions}
                                        options={{
                                            aspectRatio: isMobile ? 1 : 2,
                                            scales: {
                                                x: {
                                                    type: 'time',
                                                    time: {
                                                        tooltipFormat:
                                                            revenueFromConversionsDateFormat,
                                                    },
                                                    ticks: {
                                                        autoSkip: false,
                                                        maxRotation: 0,
                                                        major: {
                                                            enabled: true,
                                                        },
                                                    },
                                                },
                                                y: {
                                                    suggestedMin: 0,
                                                    suggestedMax: 5,
                                                    ticks: {
                                                        stepSize: revenueFromConversionsStepSize,
                                                        callback: function (value, index, ticks) {
                                                            return `$${value.toLocaleString()}`; // include dollar sign on ticks
                                                        },
                                                    },
                                                },
                                            },
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label(tooltipItem) {
                                                            return ` ${(
                                                                tooltipItem.raw as number
                                                            ).toLocaleString(undefined, {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                minimumFractionDigits: 2,
                                                            })}`;
                                                        },
                                                    },
                                                },
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                        }}
                                    />
                                </ChartContainer>
                            </ChartWrapper>
                        ) : (
                            wideChartPlaceholder(
                                'Revenue from Conversions',
                                `Revenue from Ads: ${selectedTimeRange}`
                            )
                        )}
                    </Row>
                    {!showAdsDashboard && (
                        <Overlay>
                            <OverlayBody>
                                <OverlayBigText>Interested in marketing?</OverlayBigText>
                                <BlueButton
                                    type="submit"
                                    disabled={loading}
                                    onClick={() =>
                                        window
                                            .open(
                                                'https://www.barbershopgrower.com/?utm_source=exclusively',
                                                '_blank'
                                            )
                                            ?.focus()
                                    }>
                                    Learn More
                                </BlueButton>
                            </OverlayBody>
                        </Overlay>
                    )}
                </OverlayContainer>

                <Section style={{ paddingTop: '1.5rem', paddingBottom: '3rem' }}>
                    <SectionHeader>
                        <SectionTitle>Overview</SectionTitle>
                        <SectionDescription>Overall Data: {selectedTimeRange}</SectionDescription>
                        <DisclaimerLink onClick={scrollToDisclaimer}>
                            View Disclaimer
                        </DisclaimerLink>
                    </SectionHeader>
                    <BasicTable style={{ marginTop: '1rem' }}>
                        <BasicTableHeaderRow>
                            <BasicTableHeader>Category</BasicTableHeader>
                            <BasicTableHeader>Total</BasicTableHeader>
                        </BasicTableHeaderRow>
                        <BasicTableRow>
                            <BasicTableData>Revenue</BasicTableData>
                            <BasicTableData>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(totalRevenue)}
                            </BasicTableData>
                        </BasicTableRow>
                        <BasicTableRow>
                            <BasicTableData>Appointments</BasicTableData>
                            <BasicTableData>{totalAppointments}</BasicTableData>
                        </BasicTableRow>
                        <BasicTableRow>
                            <BasicTableData>Cancelled Appointments</BasicTableData>
                            <BasicTableData>{totalCancelledAppointments}</BasicTableData>
                        </BasicTableRow>
                    </BasicTable>
                </Section>
                {!specificProviderSelected && (
                    <ServiceProviders>
                        <ServiceProvidersHeading>
                            <LeaderBoard>Leaderboard</LeaderBoard>
                            <SectionDescription>
                                Team Performance: {selectedTimeRange}
                            </SectionDescription>
                            <DisclaimerLink onClick={scrollToDisclaimer}>
                                View Disclaimer
                            </DisclaimerLink>
                        </ServiceProvidersHeading>
                        <Card>
                            {dashboardLeaderboardData?.length
                                ? dashboardLeaderboardData?.map((data: any, i: any) => {
                                      return (
                                          <CardContent key={i}>
                                              <Image
                                                  src={`https://profile-images-exclusively-s3-prd.s3.amazonaws.com/${data?.photo}`}></Image>
                                              <Name>{data?.name}</Name>

                                              <CustomDiv>
                                                  {' '}
                                                  <Number>#{i + 1}</Number>
                                              </CustomDiv>
                                              <BookedDiv>
                                                  <Booked>{data?.appointmentCount} Booked</Booked>
                                                  <CustomHr></CustomHr>

                                                  <Revenue>
                                                      {' '}
                                                      {_userData?.user?.business_id?.currency}
                                                      {data?.revenue
                                                          ? data?.revenue.toFixed(2)
                                                          : '0.00'}
                                                  </Revenue>
                                              </BookedDiv>
                                          </CardContent>
                                      );
                                  })
                                : ''}
                        </Card>
                    </ServiceProviders>
                )}
                <Row style={{ padding: '15px' }} ref={disclaimerRef}>
                    <Col lg={8} style={{ fontSize: '0.8rem', opacity: 0.8 }}>
                        The information displayed in the dashboard relies on user-provided data and
                        may not represent complete accuracy. Revenue figures should not be
                        considered for official accounting purposes. This dashboard serves to offer
                        a rapid snapshot of your business performance.
                    </Col>
                    <Col lg={4}></Col>
                </Row>
            </Content>
        </Wrapper>
    );
};

const BlueButton = styled.button`
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    line-height: inherit;
    text-transform: capitalize;
    padding: 19px 33px;
    background: rgb(27, 89, 244);
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        color: rgb(27, 89, 244);
        background: #fff;
    }
`;

const OverlayContainer = styled(Container)`
    position: relative;
`;
const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const OverlayBody = styled.div`
    background-color: #0005;
    padding: 34px 39px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const OverlayBigText = styled.div`
    color: #fff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 18px;
    ${media.md`
        font-size: 2.5rem;
    `}
    width: 100%;
    text-align: center;
`;

const Wrapper = styled(Container)`
    width: 100%;
    position: relative;
    height: 100%;
    ${media.xs`
  display: block !important;
  overflow: hidden;
`} ${media.sm`
display: block !important;
`} ${media.md`
display: flex !important;
margin:0;
min-width:100%;
`} ${media.lg`
display: flex !important;
`};
`;
const Content = styled(Row)`
    width: 100%;
    flex-direction: column;
`;

const TimeSelectionCard = styled(Col)`
    width: 100%;
    height: auto;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
`;
const WeekHeading = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
`;
const CalendarDate = styled.span`
    font-size: 1.4rem;
    opacity: 0.75;
    cursor: pointer;
    ${media.xs`
        margin-right: 15px;
    `}
    ${media.sm`
        margin-right: 15px;
    `}
    ${media.md`
        margin-right: 50px;
    `}
`;
const CalendarDate2 = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    opacity: 0.75;
    ${media.xs`
        margin-right: 15px;
    `}
    ${media.sm`
        margin-right: 15px;
    `}
    ${media.md`
        margin-right: 50px;
    `}
`;
const Hr = styled.hr`
    margin: 0;
    opacity: 0.5;
`;
const TimeSelectionSection = styled.span`
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
`;

const AppointmentsDiv = styled(Col)`
    width: 100%;
    height: auto;
    min-height: 28rem;
    padding-bottom: 1rem;
    margin-top: 0.9rem;
`;
const ChartWrapper = styled(Col)`
    &:not(:last-child) {
        padding: 0 0 15px 0;
    }
    &:last-child {
        padding: 0;
    }
    ${media.md`
        &:first-child {
            padding: 0 15px 0 0;
        }
        &:nth-child(2) {
            padding: 0;
        }
        &:nth-child(3) {
            padding: 15px 15px 0 0;
        }
        &:last-child {
            padding: 15px 0 0 0;
        }
    `}
    ${media.xxl`
        &:last-child {
            padding: 0;
        }
        &:not(:last-child) {
            padding: 0 15px 0 0;
        }
    `}
`;
const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    height: 100%;
    padding: 1.4rem;
`;
const ChartTitle = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(38, 52, 70);
`;
const ChartDescription = styled.div`
    opacity: 0.75;
`;
const DisclaimerLink = styled.a`
    opacity: 0.4;
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
`;
const NoDataText = styled.div`
    margin: 8rem auto;
    color: #a0a0a0;
`;

const ServiceProviders = styled(Col)`
    background-color: white;
    width: 100%;
    height: auto;
    min-height: 28rem;
    margin-top: 0.9rem;
    //     ${media.xs`
//     height: 25rem;
//   `} ${media.sm`
//   height: 25rem;
//   `} ${media.md`
//   height: auto;
//   `} ${media.lg`
//   height: auto;
//   `};
`;
const ServiceProvidersHeading = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
`;
const LeaderBoard = styled.span`
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 550;
`;
const CustomSpan = styled.span`
    opacity: 0.75;
`;

const Card = styled.div`
    white-space: nowrap;
    position: absolute;
    width: 95%;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 1rem;
    padding-top: 0.75rem;
`;
const CardContent = styled.div`
    height: 18rem;
    width: 16rem;
    border: 1px solid rgb(211, 211, 215, 0.5);
    border-radius: 10px;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 1rem;
    ${media.xs`
    width: 12.5rem;
    font-size: 0.9rem
  `} ${media.sm`
  font-size: 0.9rem
  `}
`;
const Image = styled.img`
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
    margin-top: 0.7rem;
`;
const Name = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 4px;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: center;
`;
const Number = styled.div`
    background-color: rgb(0 190 72 / 39%);
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    font-weight: 500;
`;
const BookedDiv = styled(Col)`
    display: flex;
    border-top: 1px solid #d3d3d7;
    height: 4rem;
    // position: relative;
    // top: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 0.7rem;
    ${media.xs`
    margin-top: 2.4rem;
  `} ${media.sm`
  margin-top: 2.4rem;
  `} ${media.md`
  `} ${media.lg`
  `};
`;
const CustomHr = styled.hr`
    opacity: 0.5;
    height: inherit;
`;
const Booked = styled.div`
    width: 100%;
    font-weight: 500;
`;
const Revenue = styled.div`
    width: 100%;
    font-weight: 500;
`;

/*
 * Section Styles
 */
const Section = styled(Col)`
    background-color: white;
    width: 100%;
    height: auto;
    margin-top: 0.9rem;
`;
const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
`;
const SectionTitle = styled.span`
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 550;
`;
const SectionDescription = styled.span`
    opacity: 0.75;
`;

/*
 * Basic Table Styles - just our own custom table
 */
const BasicTable = styled.div``;
const BasicTableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #e5e7eb;
    padding: 12px 24px;
`;
const BasicTableHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 24px;
`;
const BasicTableHeader = styled.div`
    font-weight: 550;
`;
const BasicTableData = styled.div``;

Dashboard.Layout = Layout;
export default Dashboard;
